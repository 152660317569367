@import "variables";

a {
    color: var(--bs-dark);
}

.bg-gray-100 {
    background: $gray-100;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import 'react-calendar/dist/Calendar.css';

@import "layout";
@import "responsive";
@import "ulits";
@import "branding";
@import "mapSiteCard";
@import "components/siteCard";
@import "components/siteFinder";
@import "components/booking";
@import "components/boostrapTable";
@import "components/buttons";
@import "components/dashboard";
@import "components/proSideBar";
@import "components/loginStyles";
@import "components/rcSlider";
@import "components/calendar";
@import "components/attachments";
@import "components/wysiwyg";
@import "components/compliance";
@import "components/marketing";

.input-box-style {
    border: 1px solid #F0F0F0;
    padding: .75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    box-shadow: none !important;
}

.input-box-style-mobile {
    border: 1px solid #ccc;
    padding: .8rem .4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    margin: 5px;
}

.feedback-error-info {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545
}

.extra-small {
    font-size: 1rem;
}

.checkbox-addons-bg {
    background: #B0B0B0 !important;
}

.error-icon {
    width: 25px;
    height: 25px;
}

.error-icon-small {
    width: 20px;
    height: 20px;
}

.small-checkbox {
    font-size: .875rem;
}
.small-heading {
    font-size: 1.25rem
}

.booking-show-body {
    padding: 1.75rem 1.5rem;
}

.form-control{
    &.is-invalid
    {
        border-color: $red !important;
    }

}

.site-video {
    width: 100%;
    max-width: 640px;
    height: 100%;
    @include media-breakpoint-up(md) {
        height: 360px;
    }
}
